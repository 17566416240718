<template>
  <div class="product">
    <img class="banner pointer" src="../assets/img/1_1_2.jpeg" @click="toAgent">
    <div class="content">
      <div class="header">
        <div class="left">PRODUCT</div>
        <div class="right">速绿全线产品</div>
      </div>
      <p class="desc">产品自主研发生产，完善的技术管理体系</p>
      <div class="imgBox">
        <img src="../assets/img/product_all.png" alt="全线产品图" v-if="type=='all'">
        <img src="../assets/img/product_battery.jpg" style="margin-top: 130px;" alt="便携充电宝" v-if="type=='battery'">
        <img src="../assets/img/product_desk_5.jpg" style="margin-top: 135px;" alt="桌面式5槽" v-if="type=='desk5'">
        <img src="../assets/img/product_desk_10.jpg" style="margin-top: 130px;" alt="桌面式10槽" v-if="type=='desk10'">
        <img src="../assets/img/product_desk_15.jpg" style="margin-top: 130px;" alt="桌面式15槽" v-if="type=='desk15'">
        <img src="../assets/img/product_outdoor.jpg" style="margin-top: 100px;" alt="室外机20槽" v-if="type=='outdoor'">
        <img src="../assets/img/product_adv.jpg" style="margin-top: 130px;" alt="广告机40槽" v-if="type=='adv'">
        <img src="../assets/img/product_blue.jpg" style="margin-top: 130px;" alt="蓝牙线充" v-if="type=='blue'">
        <ul class="aside">
          <li :class="{'current': type=='all'}" data-type="all" @click="changeType">速绿全线产品</li>
          <li :class="{'current': type=='battery'}" data-type="battery" @click="changeType">便携充电宝</li>
          <li :class="{'current': type=='desk5'}" data-type="desk5" @click="changeType">桌面式5槽</li>
          <li :class="{'current': type=='desk10'}" data-type="desk10" @click="changeType">桌面式10槽</li>
          <li :class="{'current': type=='desk15'}" data-type="desk15" @click="changeType">桌面式15槽</li>
          <li :class="{'current': type=='outdoor'}" data-type="outdoor" @click="changeType">室外机20槽</li>
          <li :class="{'current': type=='adv'}" data-type="adv" @click="changeType">广告机40槽</li>
          <li :class="{'current': type=='blue'}" data-type="blue" @click="changeType">蓝 牙 线 充</li>
        </ul>
      </div>
      <ul class="alt">
        <li>五秒弹出<br>一秒归还</li>
        <li>无需按键<br>即插即用</li>
        <li>低能耗</li>
        <li>实时在线<br>温度监控预警</li>
        <li>多场景<br>投放使用</li>
        <li>视频广告<br>行业领先</li>
        <li>行业内<br>寿命最长</li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      type: "all"
    };
  },
  components: {},
  mounted () {},
  methods: {
    changeType: function (event) {
        this.type = event.target.dataset.type;
    },
    toAgent(){
      this.$router.push('agent');
    },
  }
}
</script>

<style lang='scss' scoped>
  .pointer {
    cursor: pointer;
  }
  h1, h2, h3, h4, h5, p, ul, li {
    margin: 0;
    padding: 0;
    font-weight: normal;
  }
  ul {
    list-style: none;
  }
  .w {
    width: 1200px;
    margin: 0 auto;
  }
  .product {
    width: 100%;
  }
  .banner {
    width: 100%;
    height: auto;
  }
  .content {
    position: relative;
    width: 1200px;
    margin: 75px auto 110px;
    overflow: hidden;
  }
  .header {
    line-height: 40px;
    font-size: 36px;
    text-align: center;
    text-align: left;
  }
  .header div {
    display: inline-block;
    vertical-align: middle;
  }
  .header .left {
    color: #e8e8e8;
    font-weight: 700;
  }
  .header .left::after {
    content: "";
    display: inline-block;
    height: 20px;
    margin: 0 10px 0 25px;
    border-right: 1px solid #2fb15a;
  }
  .header .right {
    font-size: 34px;
    color: #000000;
  }
  .desc {
    position: absolute;
    top: 63px;
    left: 0;
    color: #000000;
    text-align: left;
  }
  .imgBox {
    height: 868px;
    text-align: left;
  }
  .imgBox img {
    margin-top: 26px;
    vertical-align: top;
  }
  .alt {
    width: 1300px;
    height: 114px;
    line-height: 22px;
  }
  .alt li {
    float: left;
    width: 110px;
    margin-right: 71px;
  }
  .alt li::before {
    content: "";
    display: block;
    margin: 0 auto;
    width: 68px;
    height: 70px;
  }
  .alt li:first-child {
    margin-left: 15px;
  }
  .alt li:nth-child(1)::before {
    margin-left: 15px;
    background: url(../assets/img/advantageIcons.png) 0 top no-repeat;
  }
  .alt li:nth-child(2)::before {
    background: url(../assets/img/advantageIcons.png) -177px top no-repeat;
  }
  .alt li:nth-child(3)::before {
    background: url(../assets/img/advantageIcons.png) -357px top no-repeat;
  }
  .alt li:nth-child(4)::before {
    background: url(../assets/img/advantageIcons.png) -538px top no-repeat;
  }
  .alt li:nth-child(5)::before {
    background: url(../assets/img/advantageIcons.png) -723px top no-repeat;
  }
  .alt li:nth-child(6)::before {
    background: url(../assets/img/advantageIcons.png) -901px top no-repeat;
  }
  .alt li:nth-child(7)::before {
    background: url(../assets/img/advantageIcons.png) -1086px top no-repeat;
  }
  .aside {
    position: absolute;
    right: 0;
    top: 300px;
    width: 110px;
    text-align: center;
  }
  .aside li {
    height: 36px;
    line-height: 36px;
    margin-bottom: 16px;
    border: 1px solid #2fb15a;
    font-size: 16px;
    color: #2fb15a;
    cursor: pointer;
  }
  .aside li.current {
    background-color: #2fb15a;
    color: #ffffff;
  }
</style>